import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import { ReactComponent as Spo2Icon } from "@static/icons/spo2.svg"
import { ReactComponent as HeartRateIcon } from "@static/icons/heart_rate.svg"
import { ReactComponent as RRIcon } from "@static/icons/rr.svg"
import { ReactComponent as PerfusionIndexIcon } from "@static/icons/perfusion_index.svg"
import { ReactComponent as PIVariabilityIcon } from "@static/icons/pi_variability.svg"
import { ReactComponent as TemperatureIcon } from "@static/icons/temperature.svg"
import { ReactComponent as EcgIcon } from "@static/icons/ecg.svg"

import {
  StyledContainer,
  Title,
  Text,
  Content,
  Image,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
} from "./style"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "bio_sign_features_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bioSign: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "sign_details_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="features" style={{ background: "#EDFBFF" }}>
      <BackgroundImage
        className="featuresBackground"
        Tag="div"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <StyledContainer>
          <Title>BIOX Pulse Oximeter features</Title>
          <Content>
            <Image>
              <Img fluid={data.bioSign.childImageSharp.fluid} />
            </Image>
            <Icon1>
              <Spo2Icon />
              <Text>Oxygen saturation</Text>
            </Icon1>
            <Icon2>
              <HeartRateIcon />
              <Text>Heart Rate</Text>
            </Icon2>
            <Icon3>
              <RRIcon />
              <Text>Heart rate variability</Text>
            </Icon3>
            <Icon4>
              <PerfusionIndexIcon />
              <Text>Perfusion index</Text>
            </Icon4>
            <Icon5>
              <PIVariabilityIcon />
              <Text>Perfusion index Variability</Text>
            </Icon5>
            <Icon6>
              <TemperatureIcon />
              <Text>Temperature</Text>
            </Icon6>
            <Icon7>
              <EcgIcon />
              <Text>ECG</Text>
            </Icon7>
          </Content>
        </StyledContainer>
      </BackgroundImage>
    </section>
  )
}

export default Features
