import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  text-align: center;
  padding-top: 64px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-top: 0;
  }
`

export const Title = styled.h3`
  font-weight: 300;
  padding-bottom: 1rem;
  margin: 2rem 0;
  user-select: none;
`

export const Text = styled.h5`
  user-select: none;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 2fr repeat(4, 1fr);
  grid-template-rows: repeat(2, 2fr) repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 70vh;
  min-height: 600px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: fit-content;
  }
`

export const Image = styled.div`
  grid-area: 1 / 4 / 3 / 7;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 1 / 1 / 2 / 3;
  }
`

export const Icon1 = styled.div`
  grid-area: 1/1/2/3;
  justify-self: center;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 2 / 1 / 3 / 2;
    align-self: center;
  }
`
export const Icon2 = styled.div`
  grid-area: 2/2/3/4;
  justify-self: start;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 2 / 2 / 3 / 3;
    justify-self: center;
    align-self: center;
  }
`
export const Icon3 = styled.div`
  grid-area: 3/3/5/5;
  justify-self: start;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 3 / 1 / 4 / 2;
    justify-self: center;
    align-self: center;
  }

  svg {
    width: 117.836px;
    height: 95.369px;
  }
`
export const Icon4 = styled.div`
  grid-area: 3/5/5/5;
  justify-self: center;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 3 / 2 / 4 / 3;
  }
`
export const Icon5 = styled.div`
  grid-area: 3/6/5/8;
  justify-self: end;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 4 / 1 / 5 / 2;
    justify-self: center;
    align-self: center;
  }

  svg {
    width: 108.918px;
    height: 108.824px;
  }
`
export const Icon6 = styled.div`
  grid-area: 2/7/3/9;
  justify-self: end;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 4 / 2 / 5 / 3;
    justify-self: center;
    align-self: center;
  }
`
export const Icon7 = styled.div`
  grid-area: 1/8/2/10;
  justify-self: center;
  align-self: start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 5 / 1 / 6 / 3;
    align-self: center;
  }
`
