import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"

import { Container } from "./style"
const Break = () => (
  <StaticQuery
    query={graphql`
      query {
        break: file(
          sourceInstanceName: { eq: "background" }
          name: { eq: "biox_sign_break_background" }
        ) {
          relativePath
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Container>
        <BackgroundImage
          Tag="section"
          fluid={data.break.childImageSharp.fluid}
          style={{ width: "100%", height: "100%" }}
        ></BackgroundImage>
      </Container>
    )}
  />
)
export default Break
