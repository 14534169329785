import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 15vh 0;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${props => props.marginTop};
  color: #007099;

  div {
    @media (max-width: ${props => props.theme.screen.sm}) {
      text-align: center;
    }
  }
`

export const Title = styled.h1`
  ${props => props.theme.font_size.large};
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  user-select: none;
`
