import styled from "styled-components"

import { Container } from "@components/global"

export const Hero = styled.div`
  height: 110vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 15vh;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 100vh;
    padding-bottom: 0;
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #000;
  margin-bottom: 3rem;
`

export const Title = styled.h1`
  margin: 2rem 0;
  font-weight: 900;
`

export const SubTitle = styled.h3`
  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
  z-index: 100;
`

export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`

export const ImgWrapper = styled.div`
  position: relative;
  width: 50vh;
`
