import React from "react"

import Layout from "@common/Layout"
import Header from "@sections/bioxSign/Header"
import About from "@sections/bioxSign/About"
import Break from "@sections/bioxSign/Break"
import Features from "@sections/bioxSign/Features"
import SlideShow from "@sections/bioxSign/SlideShow"
import Details from "@sections/bioxSign/Details"
import Brochure from "@common/Brochure"
import Contact from "@common/Contact"
import Footer from "@common/Footer"

const NAV_ITEMS = ["Info", "Features", "Details", "Contact"]

const BioSign = () => (
  <Layout title="Bio-Sign" navItems={NAV_ITEMS} color="rgba(10, 180, 233, 0.9)">
    <Header />
    <About />
    <Break />
    <Features />
    <SlideShow />
    <Details />
    <Brochure marginTop="5vh" />
    <Contact />
    <Footer />
  </Layout>
)

export default BioSign
