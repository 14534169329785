import React from "react"

import { ReactComponent as BackgroundArt } from "@static/pulse_line_3.svg"
import { InfoSection, StyledContainer, Title, Text } from "./style"

const About = () => {
  return (
    <InfoSection id="info">
      <BackgroundArt
        style={{
          position: "absolute",
          top: "0",
          bottom: "0",
          margin: "auto",
          right: "0",
        }}
      />
      <StyledContainer>
        <Title>Real-time vital sign monitoring</Title>
        <Text>
          The most essential component of examining a patient is obtaining their
          vital signs. While all other aspects of the encounter are important,
          they are meaningless if the vital signs are missed or incorrect. Vital
          signs are usually obtained at the initial moment a patient is
          evaluated. In the out-patient setting, the vital signs are normally
          recorded only once. If they are abnormal, the patient may be advised
          to seek emergency medical treatment. Research studies have suggested
          that patients in the pre-hospital setting, Emergency Room, General
          Ward, and ICU should be evaluated and monitored in a real-time basis.
        </Text>
        <Text>
          Bio-Sign, the advanced Pulse Oximeter by BIOX is an easy-to-use,
          single application device that will assist healthcare professionals to
          continuously monitor patients’ temperature, pulse, 2 lead ECG, oxygen
          saturations and temperate, among other parameters, on a continuous
          real-time basis.
        </Text>
      </StyledContainer>
    </InfoSection>
  )
}

export default About
