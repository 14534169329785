import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import { ReactComponent as BackgroundArt } from "@static/pulse_line_4.svg"

import {
  FirstContainer,
  Title,
  List,
  SecondContainer,
  Content,
  ImageWrapper,
  ListWrapper,
  CardWrapper,
  ImageWrapper1,
  CardTitle,
  CardText,
  Divider,
  Card,
  Text,
  ThirdContainer,
  OtherSection,
  CardWrapper1,
  DividerWrapper,
  BackgroundDivider,
} from "./style"

const Details = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "biox_sign_details_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupFirst: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "sign_details_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupSecond: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "sign_details_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="details" style={{ position: "relative", overflowX: "hidden" }}>
      <BackgroundArt
        style={{
          position: "absolute",
          top: "10%",
          left: "0",
        }}
      />
      <FirstContainer>
        <Title>Why BIOX Pulse Oximeter</Title>
        <Content>
          <ImageWrapper>
            <Img fluid={data.mockupFirst.childImageSharp.fluid} />
          </ImageWrapper>
          <ListWrapper>
            <List>
              <li>
                <span>
                  The vital signs are uploaded to online medical records
                  automatically. This will minimize data entry errors.
                </span>
              </li>
              <li>
                <span>
                  The advanced features of Bio-Sign ensure the private health
                  information (PHI) remains end-to-end encrypted ensuring
                  maximum security and privacy.
                </span>
              </li>
              <li>
                <span>
                  Bio-Sign offers the ease of one point of contact. This means
                  that healthcare professionals can monitor the vital signs of
                  all patients in any clinical setting from a central display
                  screen.
                </span>
              </li>
              <li>
                <span>
                  Bio-Sign has some unique features such as fall detection and
                  voice messages to healthcare professionals. It also allows
                  interactions between the patient and medical staff to
                  safeguard swift corrective action.
                </span>
              </li>
              <li>
                <span>
                  Bio-Sign stores the information about the patient's medical
                  records and vital signs in a cloud database that can be
                  assessed from anywhere and at any time. The patient is allowed
                  access to their online medical records including medical
                  history and treatments given during hospitalization.
                </span>
              </li>
            </List>
          </ListWrapper>
        </Content>
      </FirstContainer>
      <SecondContainer>
        <CardWrapper>
          <Card>
            <CardTitle>
              How can a Bio-Sign improve the monitoring of the patient's vital
              signs?
            </CardTitle>
            <CardText>
              <Text>
                Bio-Sign is an easy-to-use device that was developed with an aim
                to outperform the safety and accuracy standards recommended by
                the FDA.
              </Text>
              <Text>
                It was designed after years of clinical research to improve the
                healthcare services offered in a hospital setting.
              </Text>
              <Text>
                Bio-Sign was designed so that it can be used even in any
                clinical setting. Continuous, non-invasive monitoring of the
                patient’s blood pressure, heart rate, respiratory rate, oxygen
                saturation, and ECG using the Bio-Sign will aid in detecting
                abnormal variations earlier to avoid serious complications.
              </Text>
            </CardText>
          </Card>
        </CardWrapper>
        <ImageWrapper1>
          <Img fluid={data.mockupSecond.childImageSharp.fluid} />
        </ImageWrapper1>
      </SecondContainer>
      <DividerWrapper>
        <Divider viewBox="0 0 1920 650" aria-hidden="true">
          <path
            d="M 960 200 Q 480 0 0 200 L 0 650 L 1920 650 L 1920 200 Q 1440 400 960 200"
            fill="#84D6F3"
          ></path>
          <path
            d="M 960 200 Q 480 0 0 240 L 0 650 L 1920 650 L 1920 240 Q 1440 400 960 200 "
            fill="#0BAEE7"
          ></path>
        </Divider>
      </DividerWrapper>
      <BackgroundDivider></BackgroundDivider>
      <BackgroundImage
        className="biox_sign_details_background"
        Tag="section"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          height: "70vh",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></BackgroundImage>
      <OtherSection>
        <ThirdContainer>
          <CardWrapper1>
            <Card>
              <CardTitle>
                Why is monitoring patient's health continuously essential for
                improved hospital care?
              </CardTitle>
              <CardText>
                <Text>
                  Patients in general wards have a comparatively stable
                  condition that usually does not call for continuous
                  monitoring. Normally the vital signs of these patients are
                  checked only a few times a day. In fact, routine vital signs
                  are checked every four hours.
                </Text>
                <Text>
                  However, recent clinical studies have shown that even patients
                  in a general ward are likely to develop medical emergencies.
                  If not detected and attended to immediately, it can result in
                  life-threatening complications. This is the foundation for
                  monitoring all vital signs of all patients on a continuous
                  basis.
                </Text>
              </CardText>
            </Card>
          </CardWrapper1>
        </ThirdContainer>
      </OtherSection>
    </section>
  )
}

export default Details
