import styled from "styled-components"

import { Container } from "@components/global"

export const FirstContainer = styled(Container)`
  padding-top: 74px;
`

export const Title = styled.h3`
  font-weight: 300;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 3px solid #09bfec;
  width: fit-content;
  user-select: none;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-right: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const List = styled.ul`
  li {
    margin: 1rem 0;
    text-align: left;
    color: #09beeb;
    span {
      color: #000;
      ${props => props.theme.font_size.small};
    }
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 0px;
  margin-bottom: 10%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
`

export const ImageWrapper = styled.div`
  max-width: 300px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    height: auto;
  }
`

export const SecondContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 4rem;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Card = styled.div`
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);
  border-radius: 30px;
  padding: 3rem 2rem;
  background: #fff;
`

export const CardTitle = styled.h3`
  margin-bottom: 2rem;
`

export const CardText = styled.div``

export const Text = styled.p`
  ${props => props.theme.font_size.small};
  margin-bottom: 1rem;
`

export const ImageWrapper1 = styled.div`
  max-width: 600px;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    height: auto;
  }
`

export const ThirdContainer = styled.div`
  position: relative;
  margin-bottom: 15vh;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 2rem;
  }
`

export const CardWrapper1 = styled.div`
  display: flex;
  position: absolute;
  bottom: 5vh;

  padding: 30% 0 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    position: relative;
  }
`

export const OtherSection = styled(Container)``

export const DividerWrapper = styled.div`
  position: relative;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
`

export const BackgroundDivider = styled.div`
  height: 15vh;
  background: #0baee7;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
