import styled from "styled-components"

import { Container } from "@components/global"

export const InfoSection = styled.section`
  position: relative;
`

export const StyledContainer = styled(Container)`
  padding: 8vh 0;
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  margin-bottom: 2rem;
  font-weight: 700;
  user-select: none;

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;
  padding: 0 0 2rem;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0 1rem 0;
  }
`
