import React from "react"
import BackgroundImage from "gatsby-background-image"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import CtaButton from "@common/CtaButton"

import {
  ButtonWrapper,
  Hero,
  StyledContainer,
  Divider,
  ImgWrapper,
  Title,
  SubTitle,
} from "./style"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(
          sourceInstanceName: { eq: "background" }
          name: { eq: "biox_sign_hero_background" }
        ) {
          relativePath
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bioSign: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "sign_details_mockup_2" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        className="hero"
        Tag="section"
        fluid={data.hero.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Hero>
          <StyledContainer>
            <Title>BIO-SIGN</Title>
            <SubTitle>
              Wearable, real-time, wireless vital sign monitoring
            </SubTitle>
            <ImgWrapper>
              <Img fluid={data.bioSign.childImageSharp.fluid} />
            </ImgWrapper>
            <ButtonWrapper>
              <AnchorLink href="#info">
                <CtaButton text="Learn more" />
              </AnchorLink>
            </ButtonWrapper>
          </StyledContainer>
        </Hero>
        <Divider viewBox="0 0 1920 300" aria-hidden="true">
          <path
            d="M 0 200 Q 480 300 960 200 Q 1440 100 1920 200 L 1920 300 L 0 300 L 0 200"
            fill="#0BAEE7"
          ></path>
          <path
            d="M 0 240 Q 480 300 960 200 Q 1440 100 1920 240 L 1920 300 L 0 300 L 0 200"
            fill="white"
          ></path>
        </Divider>
      </BackgroundImage>
    )}
  />
)

export default Header
