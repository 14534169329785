import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import CtaButton from "@common/CtaButton"

import { StyledContainer, Title } from "./style"

const Brochure = ({ marginTop = 0 }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "brochure_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="brochure">
      <BackgroundImage
        className="cta"
        Tag="div"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <StyledContainer>
          <div>
            <Title>Learn more about our solutions</Title>
            <a href="https://bioxcorp.net/biox-brochure">
              <CtaButton text="Download brochure" />
            </a>
          </div>
        </StyledContainer>
      </BackgroundImage>
    </section>
  )
}

export default Brochure
