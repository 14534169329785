import React from "react"
import BackgroundSlider from "gatsby-image-background-slider"
import { graphql, useStaticQuery } from "gatsby"

import { SlideShowWrapper } from "./style"

const SlideShow = () => {
  const data = useStaticQuery(graphql`
    query {
      backgrounds: allFile(
        filter: { sourceInstanceName: { eq: "ssbioxsign" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <SlideShowWrapper>
      <BackgroundSlider query={data} />
    </SlideShowWrapper>
  )
}

export default SlideShow
